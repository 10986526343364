import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createkpi, deleteManykpis, deleteOnekpi, getKpis, getOnekpi, updatekpi } from './actions';
var initialState = {
    kpis: { docs: [], meta: {} },
    kpi: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'kpis',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // GET ALL
        builder
            .addCase(getKpis.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getKpis.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.kpis = action.payload;
        })
            .addCase(getKpis.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOnekpi.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOnekpi.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.kpis.docs = state.kpis.docs.filter(function (kpi) { return kpi._id !== action.meta.arg.kpiId; });
        })
            .addCase(deleteOnekpi.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE MANY
        builder
            .addCase(deleteManykpis.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteManykpis.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
        })
            .addCase(deleteManykpis.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // CREATE ONE
        builder
            .addCase(createkpi.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createkpi.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.kpi = action.payload.data;
        })
            .addCase(createkpi.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOnekpi.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOnekpi.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.kpi = action.payload.data;
        })
            .addCase(getOnekpi.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updatekpi.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updatekpi.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.kpi = action.payload.data;
        })
            .addCase(updatekpi.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
